<template>
    <v-form ref="vehicles">
        <form-section :sectionTitle="$t('applications.vehicles')" wideFirstCol>
            <template v-slot:col-1>
                <div v-for="vehicle in entitiesData" :key="vehicle.id">
                    <control-tool-bar
                        :id="vehicle.id"
                        title="Vehicle"
                        :removeable="vehicle.removeable"
                        @remove="removeEntity(vehicle.id)"
                    ></control-tool-bar>
                    <applicant-vehicle
                        :modelData="vehicle"
                        @update="update(vehicle.id, 'root', $event)"
                    ></applicant-vehicle>
                </div>
                <v-layout
                    class="mt-1"
                    v-if="entitiesData.length < MAX_VEHICLES"
                >
                    <v-btn
                        color="secondary"
                        class="mb-2"
                        dark
                        @click="createEntity('vehicle')"
                    >
                        <v-icon left>add_circle</v-icon
                        >{{ $t("applications.add-vehicle") }}
                    </v-btn>
                </v-layout>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-vehicles",
    components: {
        ApplicantVehicle: () => import("./ApplicantVehicle"),
        ControlToolBar: () => import("./ControlToolBar"),
    },
    mixins: [InteractsWithEntities],
    props: {},
    computed: {},

    data() {
        return {
            entityIdKey: "id",
            MAX_VEHICLES: 4,
            name: this.$t("applications.vehicles"),
        };
    },
    watch: {},
    methods: {
        validate() {
            return this.$refs["vehicles"].validate();
        },
    },
};
</script>

<style scoped>
</style>