import api from '@/components/application/api';

export default {
    props: {
        applicant_id: {
            type: [String, Number],
            required: true,
        },
        application_id: {
            type: [String, Number],
            required: true,
        },
        entities: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            entityIsLoading: false,
            entitiesData: [], //this.initializeEntitiesData(),
        };
    },
    watch: {},
    methods: {
        initializeEntitiesData() {
            this.entitiesData = this.entities.map((elem, index) => {
                // index
                if (this.mapEntityElement) {
                    elem = this.mapEntityElement(elem, index);
                }
                return elem;
            });
        },
        /**
         * Returns the element item for the given address ID
         *
         * @param {string} id  uuid of the address subkey object
         * @return void
         */
        findElementById(id) {
            return this.entitiesData.find(
                (elem) => this.$_.get(elem, this.entityIdKey) === id
            );
        },
        /**
         * Splice the element back onto the array of address elements to fire reactivity
         *
         * @param {string} id
         * @param {Object} element
         * @return void
         */
        spliceElementById(id, elem) {
            const index = this.entitiesData.findIndex(
                (elem) => this.$_.get(elem, this.entityIdKey) === id
            );
            this.entitiesData.splice(index, 1, elem);
        },
        /**
         * Updates the element at the attribute key with the input data
         *
         * @param {string} id
         * @param {string} key
         * @param {boolean | string | object} data
         * @return void
         */
        update(id, key, data) {
            let elem = Object.assign({}, this.findElementById(id));
            if (elem === undefined) {
                console.error(
                    `Unable to find element for id ${id} and key ${key} with data = %o`,
                    data
                );
            }
            if (this.preUpdate) {
                elem = this.preUpdate(id, key, elem);
            }
            if (key !== 'root') {
                elem[key] =
                    typeof data === 'object'
                        ? Object.assign(elem[key], data)
                        : data;
                // Hook to update the element
            } else {
                // Take the updated keys from the data and assign to the element
                elem = Object.assign(elem, data);
            }
            if (this.postUpdate) {
                elem = this.postUpdate(id, key, elem);
            }
            this.spliceElementById(id, elem);
            this.emitUpdate();
        },
        /**
         * Remove the addresse element from the addresses
         *
         * @param {string} id  - uuid of address sub element
         * @return void
         */
        removeEntity(id) {
            this.entitiesData = this.entitiesData.filter((elem) => {
                return this.$_.get(elem, this.entityIdKey) != id;
            });
        },
        /**
         *
         * @param {string} entity - name of entity to add (i.e address, employer, dependent)
         * @returns {Object} initialized entity with default values
         */
        async createEntity(entityName) {
            let entityData = null;
            try {
                this.entityIsloading = true;
                entityData = await api.createEntity(
                    this.application_id,
                    this.applicant_id,
                    entityName
                );
                if (this.postCreateEntity) {
                    entityData = this.postCreateEntity(entityData);
                }
                // Hook to set the location of the new entity
                if (this.addEntityToIndex) {
                    this.addEntityToIndex(entityData);
                } else {
                    // We have to use concat here so reactivity fires, can't just push a new value
                    this.entitiesData = this.entitiesData.concat(entityData);
                }
                this.entityIsLoading = false;
            } catch (error) {
                // TODO: HANDLE ERROR
                console.log(
                    `Error creating entity ${entityName} with error = ${error}`
                );
                this.entityIsLoading = false;
            }
            return entityData;
        },
        emitUpdate() {
            this.$emit('update', this.entitiesData);
        },
    },
    mounted() {
        // This mixin requires defining the Entity ID Key (i.e. address.id)
        if (!this.entityIdKey) {
            console.error('Missing required data element entityIdKey');
        }
        this.initializeEntitiesData();
    },
};
