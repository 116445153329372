var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "vehicles" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.vehicles"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _vm._l(_vm.entitiesData, function(vehicle) {
                  return _c(
                    "div",
                    { key: vehicle.id },
                    [
                      _c("control-tool-bar", {
                        attrs: {
                          id: vehicle.id,
                          title: "Vehicle",
                          removeable: vehicle.removeable
                        },
                        on: {
                          remove: function($event) {
                            return _vm.removeEntity(vehicle.id)
                          }
                        }
                      }),
                      _c("applicant-vehicle", {
                        attrs: { modelData: vehicle },
                        on: {
                          update: function($event) {
                            return _vm.update(vehicle.id, "root", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm.entitiesData.length < _vm.MAX_VEHICLES
                  ? _c(
                      "v-layout",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mb-2",
                            attrs: { color: "secondary", dark: "" },
                            on: {
                              click: function($event) {
                                return _vm.createEntity("vehicle")
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("add_circle")
                            ]),
                            _vm._v(
                              _vm._s(_vm.$t("applications.add-vehicle")) + " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }